<template>
  <div class="overflow-auto">
    <b-button @click="changePage(1)">Làm mới</b-button>
    <b-overlay :show="loading">
      <b-table
        id="my-table"
        :fields="fields"
        :items="items"
        :per-page="0"
        :current-page="currentPage"
      >
        <template
          #cell(balance)="data"
        >
          <col
            :style="{ width: '300px' }">
          {{ Intl.NumberFormat().format(data.item.new_balance) }}  {{ data.item.currency }} <small style="color:green">{{ data.item.new_balance > data.item.old_balance ? '+' : '-' }} {{ Intl.NumberFormat().format(data.item.balance) }}</small>
          </col>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | moment("H : mm , D/M/YYYY") }}
        </template>
      </b-table>
    </b-overlay>
    <b-pagination
      v-if="total > perPage"
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      aria-controls="my-table"
      @change="changePage"
    />
  </div>
</template>

<script>

import { BTable, BPagination, BOverlay, BButton } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      fields: [
        {
          key: 'balance',
          label: 'Số dư',
        },
        {
          key: 'name',
          label: 'Người gửi',
        },
        {
          key: 'type',
          label: 'Kiểu hành động',
        },
        {
          key: 'description',
          label: 'Mô tả',
        },
        {
          key: 'created_at',
          label: 'Thời gian tạo',
        },
        {
          key: 'order_code',
          label: 'Mã đơn hàng',
        },
        {
          key: 'transaction_code',
          label: 'Mã giao dịch',
        },
      ],
      perPage: 10,
      currentPage: 1,
      items: [],
      total: 0,
      loading: false,
    }
  },
  components: {
    BOverlay,
    BButton,
    BTable,
    BPagination,
  },
  mounted() {
    this.changePage(1)
  },
  methods: {
    changePage(e) {
      this.loading = true
      useJwt.getTransactionHistory(e).then(res => {
        this.loading = false
        this.$set(this, 'items', res.data.data)
        if (e === 1) this.$set(this, 'total', res.data.total)
        this.$set(this, 'currentPage', e)
      })
    },
  },
}
</script>
